.others .title{
  text-transform: uppercase;
  text-align: center;
  padding: 10px 0;
}

.others .title strong{
  font-size: 24px;
  margin-right: 10px;
}

.others .title span{
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
  line-height: 14px;
}

.others-games .col-xs-6{
  padding: 0;
}

.others-games .others-title{
  font-size: 18px;
}

.others-gamebutton{
  height: 116px;
}

.others-gamebutton:hover .gamebutton-hover,
.others-gamebutton.active .gamebutton-hover{
  opacity: 1;
}

.others-gamebutton:hover .game-details .btn-yellow-2,
.others-gamebutton.active .game-details .btn-yellow-2{
  background-color: #000000;
}

.others-games .gamebutton-hover{
  top: -2px;
}

.others-gamebutton-1 { background: url("../images/others/other-button-1.png") right no-repeat; }
.others-gamebutton-2 { background: url("../images/others/other-button-2.png") right no-repeat; }

.others-content{
  height: 768px;
  background: url("../images/preloader.gif") center center no-repeat #e4e4e4;
  margin-bottom: 10px;
}
