.ngdialog-login.ngdialog.ngdialog-theme-default .ngdialog-content{
  width: 400px;
  background: transparent;
}

.ngdialog-login-page{
  background-color: #ffffff;
}

.ngdialog-login-page .logo{
  height: 65px;
  text-align: center;
  line-height: 65px;
}

.ngdialog-login-page .ngdialog-login__content {
  border: 1px solid #dddfe2;
  padding: 30px 50px;
}

.ngdialog-login-page .ngdialog-login__content p {
  font-size: 18px;
  color: #666666;
}

.ngdialog-login-page .ngdialog-login__content form {
  margin-bottom: 20px;
}

.ngdialog-login-page .form-control{
  background: #f2f2f2;
  height: 40px;
  border-radius: 0;
  border-color: #dddfe2;
  border-left: 0;
  color: #666666;
  line-height: 40px;
  box-shadow: none;
}

.ngdialog-login-page .login-title{
  color: #aeaeae;
  font-size: 16px;
  margin-bottom: 20px;
}

.ngdialog-login-page .btn-gray{
  border-radius: 0;
}

.ngdialog-login-page .ngdialog-login__header{
  padding: 21px 10px;
  background: #f5aa18;
  /*height: 65px;*/
}

.ngdialog-login .input-group-addon{
  background: #f2f2f2;
  border: 1px solid #dddfe2;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border-right: 0;
  position: relative;
  padding: 6px 15px;
}

.input-group-addon .fa-user:before,.input-group-addon .fa-lock:before{
  color: #eaac33;
}

.ngdialog-login .input-group-addon:after{
  content: "";
  position: absolute;
  height: 15px;
  width: 2px;
  background-color: #dddfe2;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ngdialog-login .input-group {
  margin-bottom: 10px;
}

.ngdialog-login .input-group.second-input {
  margin-bottom: 20px;
}
