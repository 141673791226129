.poker-container{
  background: url("../images/poker/poker-banner.png") top center no-repeat #000000;
  height: 161px;
}

.poker .title{
  color: #ffffff;
  padding-left: 35px;
  padding-top: 45px;
  text-transform: uppercase;
}

.poker .title h1{
  font-weight: bold;
  margin: 0;
}

.poker-games .col-xs-12{
  padding: 0;
}

.poker-gamebutton{
  height: 208px;
}

.poker-gamebutton .game-details{
  width: 100%;
}

.poker-games .poker-title{
  font-size: 29px;
}

.poker-gamebutton:hover .gamebutton-hover,
.poker-gamebutton.active .gamebutton-hover{
  opacity: 1;
}

.poker-gamebutton:hover .game-details .btn-yellow-2,
.poker-gamebutton.active .game-details .btn-yellow-2{
  background-color: #000000;
}

.poker-games .gamebutton-hover{
  bottom: -92px;
}

.poker-gamebutton-1 { background: url("../images/poker/poker-button-1.png") no-repeat; }
