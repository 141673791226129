.promotion-2 .title{
  padding: 20px 0;
  text-align: center;
  text-transform: uppercase;
}

.promotion-2 .title strong{
  font-size: 24px;
  margin-right: 10px;
}

.promotion-2 .title span{
  display: inline-block;
  font-weight: 700;
  height: 22px;
  line-height: 14px;
  vertical-align: middle;
}

.promotion-2-container .col-xs-4{
  padding: 0;
}

.promotion-2-container .items{
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  box-shadow: 2px 2px 10px #e2e2e2;
  min-height: 300px;
  padding: 20px;
  margin: 0 10px 10px;
}

.promotion-2-container .items:hover{
  box-shadow: 2px 2px 10px #cccccc;
  border-color: #b3b3b3;
}

.promotion-2-container .items img{
  width: 80%;
  margin-bottom: 10px;
}

.promotion-2-container .items h1{
  margin: 0;
  font-size: 20px;
  font-weight: 700;
}

.ngdialog.ngdialog-theme-default.ngdialog-promotion .ngdialog-content{
  width: 953px;
  padding: 5px;
  border-radius: 6px;
}

.ngdialog-promotion h1{
  color: #dfa030;
  font-size: 20px;
  font-weight: 700;
}
.ngdialog-promotion .col-xs-4{
  border-right: 1px solid #ccc;
  padding-top: 65px;
  padding-bottom: 65px;

}

.promotion-2-container .btn-yellow,
.ngdialog-promotion .btn-yellow {
  width: 140px;
  height: 40px;
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
  font-size: 14px;
}

.promotion-2-container .btn-yellow:hover,
.ngdialog-promotion .btn-yellow:hover {
  background: #666666;
}
