.ngdialog-customer-page .ngdialog__heading {
  border: 1px solid #dddfe2;
  height: 65px;
  line-height: 65px;
  text-align: center;
}

.ngdialog-customer-page form fieldset{
  padding: 10px 0;
}

.ngdialog-customer-page form fieldset label {
  font-size: 14px;
}

.ngdialog-customer-page form fieldset:last-of-type {
  padding-bottom: 20px;
}

.ngdialog-customer-page form .form-control,
.coupon .form-control{
  border-radius: 0;
  box-shadow: none;
  background: #f2f2f2;
  border: 1px solid #dddfe2;
}

.ngdialog-customer-page form .form-control:focus{
  border-color: #dddfe2;
}

.ngdialog-customer-page .panel{
  margin-bottom: 10px;
}

.ngdialog-customer-page .history-table.table > tbody > tr > td a{
  color: #666666;
}

.ngdialog-customer-page .history-table.table > tbody > tr > td a:hover,
.ngdialog-customer-page .history-table.table > tbody > tr > td a:focus,
.ngdialog-customer-page .history-table.table > tbody > tr > td a:active {
  text-decoration: none;
  color: #eaac33;
}

.ngdialog-customer-page .history-table > tbody > tr > td a span + span {
  color: #9b001b;
  font-weight: bold;
}

.ngdialog-customer-page .panel-primary.read{
  margin-left: 30px;
}

.ngdialog-customer-page .btn-submit {
  width: 50%;
  margin: 0 auto;
}
