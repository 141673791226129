body {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  background: #f6f6f6;
  color: rgb(102, 102, 102);
  min-width: 128rem;
}

a:hover,
a:focus{
  text-decoration: none;
}

.btn{
  border-radius: 0;
}

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
  outline: none;
}

.list-inline{
  margin-bottom: 0;
  margin-left: 0;
}

.main-container {
  width: 1260px;
  margin: 0 auto;
  position: relative;
}

.main-content {
  width: 100%;
}

/* top-header */
.top-header{
  background-color: #f2f2f2;
  color: #666666;
  font-weight: 600;
  font-size: 14px;
}

.top-links {
  height: 40px;
  line-height: 2;
  text-align: right;
}

/* header */
.header{
  background-color: #ffffff;
  height: 90px;
}

.header__container {
  display: flex;
  justify-content: space-between;
}

.header .logo {
  height: 90px;
  padding: 10px 0;
}

/* ngdialog */
body.ngdialog-open {
  padding: 0 !important;
}

.ngdialog-overlay {
  background: rgba(0, 0, 0, 0.7);
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
  border-radius: 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
  line-height: 1.5;
  padding: 0;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:before {
  color: #666666;
  width: 65px;
  height: 65px;
  line-height: 65px;
  right: 0;
  top: 0;
}

.ngdialog.ngdialog-theme-default .ngdialog-close:hover:before,
.ngdialog.ngdialog-theme-default .ngdialog-close:active:before{
  color: #eaac33;
}

/*swal*/
.swal-text {
  text-align: center;
}
.csrf-swal .swal-title,
 .csrf-swal .swal-text{
   font-size: 27px;
   font-weight: 600;
   color: rgba(0,0,0,.65);
 }

.no-padding {
  padding: 0 !important;
}

select{
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("/common/images/chevron-down-icon.svg") #f2f2f2 center right 10px no-repeat !important;
  border-radius: 0;
}

textarea {
  resize: none;
  height: auto !important;
}

.btn-submit {
  height: 45px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9ac33+0,b4711f+100 */
  background: #e9ac33; /* Old browsers */
  background: -moz-linear-gradient(top,  #e9ac33 0%, #b4711f 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #e9ac33 0%,#b4711f 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #e9ac33 0%,#b4711f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ac33', endColorstr='#b4711f',GradientType=0 ); /* IE6-9 */
  border: 0;
  color: #ffffff;
}

.btn-submit:hover {
  background: #666666;
  border: 0;
  color: #ffffff;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background: #666666;
  color: #ffffff;
}


.btn-group button {
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
  border: 0;
  color: #fff;
  height: 34px;
}

.transfer__btn-group.btn-group > div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  gap: 5px;
}

.btn-group, .btn-group-vertical {
  display: flex;
}

.transfer .btn-group button {
  width: 80px;
  padding: 0;
}

.deposit .btn-group button,
.withdraw .btn-group button{
  width: 65px;
  margin-right: 5px;
  padding: 0;
}

.deposit .btn-group button.btn-clear,
.withdraw .btn-group button.btn-clear{
  width: 64px ;
  padding: 0;
}

.btn-group button.btn-clear {
  background: #666666;
  margin-right: 0;
}

.alert.alert-danger {
  background: #666666;
  color: #fff;
  border: 0;
}

.ngdialog-wallet-page form fieldset:last-of-type {
  margin-bottom: 30px;
  padding-bottom: 0;
  border-bottom: none;
}

.btn-group button:hover {
  background: #666666;
  border: 0;
}

.btn-group button.btn-clear:hover {
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
  border: 0;
}

.width10 {
  width: 10% !important;
}

.width15 {
  width: 15% !important;
}

.width20 {
  width: 20% !important;
}

.width30 {
  width: 30% !important;
}
