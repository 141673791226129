.sub-link{
  display: inline-block;
  margin: 5px 0;
}

.sub-link li {
  position: relative;
  padding: 0 20px;
}

.sub-link li::after {
  content: "";
  width: 2px;
  height: 50%;
  position: absolute;
  right: 0;
  top: 50%;
  background: #dddfe2;
  transform: translateY(-50%);
}

.sub-link li i {
  margin-right: 5px;
}
