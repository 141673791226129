.ngdialog-notice.ngdialog.ngdialog-theme-default .ngdialog-content{
  width: 600px;
  background: #ffffff;
}

.ngdialog-notice-page{
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.ngdialog-notice-container {
  padding: 20px;
  border: 1px solid #dddfe2;
}

.ngdialog-notice__title{
  height: 65px;
  text-align: center;
  line-height: 65px;
}

.ngdialog-notice__title h4{
  margin: 0;
  font-weight: bold;
}

.ngdialog-notice__content{
  padding: 10px;
  height: 300px;
  overflow-y: scroll;
}

.ngdialog-notice__content__footer{
  padding: 10px 20px;
  border: 1px solid #dddfe2;
  border-top: 0;
}

.ngdialog-notice__content__title h5,
.ngdialog-notice__content__footer h5{
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
}

.ngdialog-notice__content__footer .btn.btn-yellow{
  height: 40px;
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
  border: 0;
}

.ngdialog-notice__content__footer .btn.btn-yellow:hover {
  background: #666666;
}


.ngdialog-agreement.ngdialog.ngdialog-theme-default .ngdialog-content{
  width: 600px;
  background: #ffffff;
}

.ngdialog-agreement-page{
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.ngdialog-agreement-container {
  /*padding: 10px 50px 13px 50px;*/
  border: 1px solid #dddfe2;
}


.ngdialog-agreement__title{
  height: 65px;
  text-align: center;
  line-height: 65px;
}

.text-rules{
  font-weight: 700;
  color: #de9f2e;
  font-size: 15px;
  font-family: 'Roboto Black';
}

/*.ngdialog-agreement__content{*/
/*  font-family: 'Unicode';*/
/*}*/
