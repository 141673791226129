.navigation {
  height: 40px;
}

.navs {
  float: left;
  width: 100%;
}

.navs > li {
  float: left;
  padding: 0;
}

.navs li {
  width: 42px;
}

.navs li:nth-child(n+3) {
  width: calc(1176px / 8);
}

.navs a {
  display: block;
  font-weight: 700;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.navs a.navOthers {
  background: url("/common/images/nav-icon.png") top right no-repeat;
  text-align: left;
  padding-left: 5px;
}

.navs li:nth-child(7),
.navs li:last-child {
  border-right: 0;
}

/*nav hover*/

.navigation .sub-nav-container{
  width: 1260px;
  position: relative;
  margin: 0 auto;
  padding: 10px 0;
}

.sports-container-hover,
.live-container-hover,
.slot-container-hover,
.fish-container-hover{
  display: none;
  z-index: 999;
  position: absolute;
  top: 170px;
  width: 100%;
}

.sports-container-hover .content,
.live-container-hover .content,
.slot-container-hover .content,
.fish-container-hover .content{
  background: #fff;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 2px 5px 5px 1px rgb(0 0 0 / 45%);
  -webkit-box-shadow: 2px 5px 5px 1px rgb(0 0 0 / 45%);
  -moz-box-shadow: 2px 5px 5px 1px rgba(0,0,0,0.45);
}

.navigation .sports-button,
.navigation .live-button,
.navigation .slot-button,
.navigation .fish-button{
  width: calc(100%/3 - 7px);
  float: left;
  margin: 10px 5px;
  position: relative;
}

.navigation .slot-button {
  width: calc(100%/4 - 8px);
}

.navigation .sports-button:nth-child(3n+1),
.navigation .live-button:nth-child(3n+1),
.navigation .slot-button:nth-child(4n+1),
.navigation .fish-button:nth-child(3n+1){
  margin-left: 0;
}

.navigation .sports-button:nth-child(3n+3),
.navigation .live-button:nth-child(3n+3),
.navigation .slot-button:nth-child(4n+4),
.navigation .fish-button:nth-child(3n+3){
  margin-right: 0;
}

.navigation .sports-button img,
.navigation .live-button img,
.navigation .slot-button img,
.navigation .fish-button img{
  width: 100%;
}

.sub-nav-button__container {
  position: absolute;
  width: 100%;
  top: 112px;
  padding: 20px;
}

.sub-nav-button__container a {
  display: block;
  width: 100px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  border-radius: 20px;
  border: 1px solid #fff;
}

.sub-nav-button__container a:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9ac33+0,b4711f+100 */
  background: #e9ac33; /* Old browsers */
  background: -moz-linear-gradient(top,  #e9ac33 0%, #b4711f 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #e9ac33 0%,#b4711f 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #e9ac33 0%,#b4711f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ac33', endColorstr='#b4711f',GradientType=0 ); /* IE6-9 */
  border: 1px solid #e9ac33;
}

.sub-nav-button__container a:first-child {
  margin-bottom: 10px;
}

.live-button .sub-nav-button__container ,
.slot-button .sub-nav-button__container ,
.fish-button .sub-nav-button__container {
  top: 160px;
}

.slot-button .sub-nav-button__container {
  top: 90px;
}
