.slot-view-container {
  margin: 10px 0;
  padding: 15px;
  background: #f2f2f2;
  border: 1px solid #dddfe2;
}

.gamebutton-container.slot-nav {
  margin: 20px 0;
}

.slots-buttons-container{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.slots-buttons-container .slots-buttons{
  height: 45px;
  padding: 0 30px;
  background: #666666;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: all .5s;
}

.slot-category{
  padding: 0 16px;
}

.slots-buttons-container .slots-buttons:hover,
.slots-buttons-container .slots-buttons.active,
.slot-category:hover,
.slot-category.active{
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9ac33+0,b4711f+100 */
  background: #e9ac33; /* Old browsers */
  background: -moz-linear-gradient(top,  #e9ac33 0%, #b4711f 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #e9ac33 0%,#b4711f 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #e9ac33 0%,#b4711f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ac33', endColorstr='#b4711f',GradientType=0 ); /* IE6-9 */
}

/*slot games*/

/*view slots*/

.slot-wrapper{
  width: 100%;
  overflow-x: hidden;
}
.wrapper__content{
  margin: 0 auto;
}
.slots .sub-slot-button{
  background-color: #0a0a0a;
}

.slots .sub-slot-button li{
  text-transform: uppercase;
  line-height: 48px;
  text-align: center;
  padding: 1px 30px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
}

.slots .sub-slot-button li.active {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9ac33+0,b4711f+100 */
  background: #e9ac33; /* Old browsers */
  background: -moz-linear-gradient(top,  #e9ac33 0%, #b4711f 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #e9ac33 0%,#b4711f 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #e9ac33 0%,#b4711f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ac33', endColorstr='#b4711f',GradientType=0 ); /* IE6-9 */
}

.slots .bbin-page{margin-bottom: 10px; cursor: pointer;}

.slots .video-container{
  position: relative;
}
.slots .video-frame{
  position: absolute;
  width: 100%;
  top:0
}
.slots .video-panel{
  position: absolute;
  width: 100%;
  margin: 56px 0 0 10px;
}

.slots .video-panel.video-panel-1005{
  margin: 56px 0 0 275px;
}

.slots .place-holder-layout-title{
  width: 100%;
  height: 28px;
  background: #d5d5d5;
  position: absolute;
  bottom: 0;
}

/*SLOT GAMES*/
.slot-wrapper__content{
  display: flex;
  flex-wrap: wrap;
}

.slot-box{
  position: relative;
  font-size: 13px;
  overflow: hidden;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: -0.4px;
  width: calc(1230px / 7 - 8px);
  height: 205px;
  background: transparent;
  border: solid 1px #dddfe2;
  transition: 0.5s;
  margin-bottom: 9px;
  margin-right: 9px;
}

.play.slot-category{
  background: #e9ac33;
  background: -moz-linear-gradient(top, #e9ac33 0%, #b4711f 100%);
  background: -webkit-linear-gradient(top, #e9ac33 0%,#b4711f 100%);
  background: linear-gradient(to bottom, #e9ac33 0%,#b4711f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ac33', endColorstr='#b4711f',GradientType=0 );
  height: 40px;
  border: 0;
  color: #ffffff;
  border-radius: 0;
  padding: 0 20px;
  position: relative;
  top: 40%;
}

.play.slot-category:hover{
  background: #666666;
  color: #ffffff;
}

.slot-box:nth-of-type(7n+7) {
  margin-right: 0;
}

.slot-box.slot-box-1071 {
  height: 171px;
}
.slot-box.slot-box-1102{
  height: 180px;
}
.slot-box--hover {
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid #eaac33;
  height: 100%;
  opacity: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 9;
}

.slot-box:hover .slot-box--hover {
  cursor: pointer;
  opacity: 10;
  transition: opacity 0.2s ease-in-out;
}


.slot-box__item{
  height: 165px;
  width: 100%;
}

.slot-box__item.slot-1052{
  height: 215px;
}

.slot-content-1052 .slot-box--hover .btn {
  margin-top: 35%;
}

.slot-box__name{
  background-color: #0a0a0a;
  bottom: 0;
  color: #ffffff;
  margin: 0;
  overflow: hidden;
  padding: 0 5px;
  position: absolute;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  height: 40px;
  line-height: 40px;
}

/*SLOT GAMES*/

.gamebutton-container .slots-button,
.gamebutton-container .sub-slot-button{
  font-size: 14px;
  width: 100%;
}

.gamebutton-container .sub-slot-button{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slot-box__item.slot-1080{
  height: 181px;
}


.slot-box__item.slot-1015{
  height: 142px;
}

.slots .main-container.clearfix{
  border: 2px solid #e2e2e2;
  margin-top: 7px;
}

.slots .page-banner>img{
  height: 150px;
}

.slot-box:hover .slot-box__item {
  transform: scale(1.4);
  transform-origin: 50% 50%;
  transition: .3s ease-in-out;
  -webkit-transition: .3s ease-in-out;
}

.slot-box:hover{
  border:thin solid #f5aa18;
}

.slot-box--hover p{
  margin-top: 50%;
  color: #ffffff;
  white-space: nowrap;
}

/*
 *  STYLE 4
 */


#slots-con::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,1);
  background-color: #000000;
}

#slots-con::-webkit-scrollbar
{
  width: 14px;
  background-color: #f0c101;
}

#slots-con::-webkit-scrollbar-thumb
{
  background-color: #f0c101;
  border: 2px solid #f0c101;
}

.slot-game-nav{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.search-slot{
  width: 240px;
  height: 40px;
  color: #aeaeae;
  padding-left: 50px;
  background: url(/common/images/icons/icon-search.svg) left 20px center no-repeat #222222;
  background-size: 18px;
  border-color: #222222;
  margin-right: 5px;
}

.gamebutton-container.slot-games-butons{
  padding: 0;
  margin: 0;
}

.loadingGifSlot{
  position: absolute;
  bottom: 50px;
}

