.customer-service-container {
  margin: 15px;
  border-bottom: 2px solid #dddfe2;
}

.customer-service .table {
  margin: 0;
}

.customer-service .table > tbody > tr > td {
  width: calc(100% / 2);
  border: 0;
  padding: 10px;
}

.customer-service .table > tbody > tr > td .contact {
  padding-bottom: 10px;
}

.customer-service .table tr td .contact img {
  margin-right: 10px;
}

.progress-container {
  margin: 15px;
}

.progress-container .items h1 {
  font-size: 18px;
  font-weight: 600;
  color: #eaac33;
  text-transform: uppercase;
  display: inline-block;
  margin: 0 0 5px 0;
}

.progress-container .items .average-time {
  display: inline-block;
  float: right;
  color: #666666;
  font-size: 12px;
  padding-right: 5px;
}

.progress-container .items .average-time h2 {
  display: inline-block;
  font-weight: 700;
  font-size: 18px;
  margin: 0;
  color: #eaac33;
}

.progress-container .items .progress {
  height: 25px;
  border-radius: 12px;
  background: #f2f2f2;
}

.progress-container .items .progress-bar.first{
  width: 20%;
  animation: loadFirst 500ms linear 1s ;
  animation-fill-mode: forwards;
}

.progress-container .items .progress-bar.second{
  width: 50%;
  animation: loadSecond 500ms linear 1s;
  animation-fill-mode: forwards;
}

.progress-container .items .progress-bar {
  border-radius: 10px;
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
}
