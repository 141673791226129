.casino-container{
  background: url("/common/images/banner/main-banner-live.jpg") top center no-repeat #250d1d;
  height: 450px;
}

.gamebutton-container .game-button.live-button-1027,
.gamebutton-container .game-button.live-button-1009,
.gamebutton-container .game-button.live-button-1097,
.gamebutton-container .game-button.live-button-1058,
.gamebutton-container .game-button.live-button-1081,
.gamebutton-container .game-button.live-button-1012,
.gamebutton-container .game-button.live-button-1147,
.gamebutton-container .game-button.live-button-1118,
.gamebutton-container .game-button.live-button-1157,
.gamebutton-container .game-button.live-button-1186,
.gamebutton-container .game-button.live-button-1172 {
  background-size: cover !important;
}

.gamebutton-container .game-button.live-button-1012 {
  background: url("/common/images/gamebuttons/live/live-button-1012.png") no-repeat;
}
.gamebutton-container .game-button.live-button-1147 {
  background: url("/common/images/gamebuttons/live/live-button-1147.png") no-repeat;
}
.gamebutton-container .game-button.live-button-1118 {
  background: url("/common/images/gamebuttons/live/live-button-1118.png") no-repeat;
}
.gamebutton-container .game-button.live-button-1157 {
  background: url("/common/images/gamebuttons/live/live-button-1157.png") no-repeat;
}
.gamebutton-container .game-button.live-button-1027 {
  background: url("/common/images/gamebuttons/live/live-button-1027.png") no-repeat;
}
.gamebutton-container .game-button.live-button-1009 {
  background: url("/common/images/gamebuttons/live/live-button-1009.png") no-repeat;
}
.gamebutton-container .game-button.live-button-1097 {
  background: url("/common/images/gamebuttons/live/live-button-1097.png") no-repeat;
}
.gamebutton-container .game-button.live-button-1058 {
  background: url("/common/images/gamebuttons/live/live-button-1058.png") no-repeat;
}
.gamebutton-container .game-button.live-button-1081 {
  background: url("/common/images/gamebuttons/live/live-button-1081.png") no-repeat;
}
.gamebutton-container .game-button.live-button-1186 {
  background: url("/common/images/gamebuttons/live/live-button-1186.png") no-repeat;
}
.gamebutton-container .game-button.live-button-1172 {
  background: url("/common/images/gamebuttons/live/live-button-1172.png") no-repeat;
}

.casino .button-container {
  top: 150px;
}

.casino .promo-text-slider {
  position: absolute;
  top: 135px;
  left: 130px;
  width: 410px;
  height: 190px;
  text-align: center;
}

.casino .promo-text-slider img {
  height: 100%;
}

.casino .promo-text-slider .promo-text-btn {
  width: 200px;
  height: 40px;
  border-radius: 20px;
  border: 0;
  color: #ffffff;
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
}
