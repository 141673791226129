.footer-page {
  padding-bottom: 100px;
}

.footer-page .main-container {
  text-align: center;
}

.footer-page img {
  width: calc(100% / 8);
}

.footer-page p {
  text-transform: uppercase;
  color: #666666;
  font-size: 14px;
  font-weight: 600;
  padding: 40px 0 0 0;
}
