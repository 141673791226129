.footer-information{
  padding-bottom: 20px;
}

.footer-information .main-container{
  border: 1px solid #c7c7c7;
  background-color: #ffffff;
}

.footer-information .col-xs-3{
  padding: 0;
}

.footer-information .title{
  text-transform: uppercase;
  margin-bottom: 0;
  background-color: #fafafa;
  padding: 10px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
}

.footer-information .information{
  padding: 0 15px;
}

.footer-information .payment{
  padding: 5px 10px;
  text-transform: uppercase;
  background-color: #fafafa;
}
