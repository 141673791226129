.mobile-container{
  background-color: #f4f0f1;
  padding: 10px;
}

.mobile-container h1{
  font-weight: 700;
  text-transform: uppercase;
}

.mobile-container ul li{
  font-size: 13px;
  font-weight: 700;
}

.mobile-container ul li::before {
  content: "• ";
}

.mobile .mobile-banner{
  position: absolute;
  right: 0;
  top: -183px;
}

.mobile .list-inline li{
  font-weight: 700;
  text-align: center;
}
