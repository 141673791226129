.announcement p{
  margin-bottom: 0;
  display: inline-block;
}

.newsticker{
  display: inline-block;
  position: relative;
  top: 4px;
}

.newsticker .list-unstyled{ margin-bottom: 0; }
