.jackpot{
  background: url("/common/images/jackpot.jpg") 0 0 no-repeat;
  height: 100px;
  margin: 10px 0;
}

.jackpot__title{
  color: #ffffff;
  display: inline-block;
  font-size: 24px;
  font-weight: 700;
  line-height: 100%;
  padding: 15px 13px;
  text-align: right;
  text-transform: uppercase;
  vertical-align: middle;
}

.jackpot__dollar{
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  bottom: 10px;
  right: 570px;
}

.jackpot__currency{
  display: inline-block;
  float: right;
  padding: 13px 13px;
  margin-right: 100px;
}

.jackpot-number{
  bottom: 10px;
  position: absolute;
  right: 110px;
}

.jackpot-odometer{
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 540px;
}
