.promotion-slider-container {
  width: 390px;
  height: 282px;
}

.promotion .slick-slide img {
  display: inline-block;
  padding-bottom: 15px;
}

.promotion .slick-dotted.slick-slider {
  margin-bottom: 0;
}

.promotion .slick-dots li {
  margin: 0;
}

.promotion .slick-dots li button:before {
  font-size: 10px;
  color: #dddfe2;
  opacity: 1;
}

.promotion .slick-dots li.slick-active button:before {
  color: #dd9e2f;
  opacity: 1;
}

.promotion .slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: .75;
}

.promotion .promotion-slider-container img {
  cursor: pointer;
}
