.guide-container {
  background: #250d1d;
  height: 120px;
}

.guide-container .guide--content {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.guide-container .guide--content .guide-1 {
  display: flex;
  justify-content: space-between;
  height: 120px;
  padding: 28px 20px;
  cursor: pointer;
}

.guide-container .guide--content .guide-1 a {
  display: flex;
  justify-content: space-between;
}

.guide-container .guide--content .guide-1 .guide-join,
.guide-container .guide--content .guide-1 .guide-deposit,
.guide-container .guide--content .guide-1 .guide-play {
  width: 65px;
  height: 65px;
}

.guide-container .guide--content .guide-1 .guide-join {
  background: url("/common/images/icons/icon-three-step-guide-join.svg") center transparent no-repeat;
}
.guide-container .guide--content .guide-1 .guide-deposit {
  background: url("/common/images/icons/icon-three-step-guide-deposit.svg") center transparent no-repeat;
}
.guide-container .guide--content .guide-1 .guide-play {
  background: url("/common/images/icons/icon-three-step-guide-play.svg") center transparent no-repeat;
}

.guide-container .guide--content .guide-1 .guide-text {
  padding: 10px 20px;
}

.guide-container .guide--content .guide-1 .guide-text p {
  margin: 0;
}

.guide-container .guide--content .guide-1 .guide-text p.text-yellow {
  color: #eaac33;
  font-size: 20px;
  font-weight: 600;
}

.guide-container .guide--content .guide-1 .guide-text p.text-white {
  color: #ffffff;
  font-size: 13px;
}

.guide-container .guide--content .arrow-guide {
  line-height: 120px;
}
