.fish-container{
  background: url("/common/images/banner/main-banner-fish.jpg") top center no-repeat #250d1d;
  height: 450px;
}

.gamebutton-container .game-button.fish-button-1094 {
  background: url("/common/images/gamebuttons/fish/fish-button-horizontal-1094.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 300px;
}

.fish .button-container {
  top: 220px;
}

.fish .button-container a {
  margin: 0 auto;
}

.fish .promo-text-slider {
  position: absolute;
  top: 135px;
  left: 130px;
  width: 410px;
  height: 190px;
  text-align: center;
}

.fish .promo-text-slider img {
  height: 100%;
}

.fish .promo-text-slider .promo-text-btn {
  width: 200px;
  height: 40px;
  border-radius: 20px;
  border: 0;
  color: #ffffff;
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
}
