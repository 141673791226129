.slots-container{
  background: url("/common/images/banner/main-banner-slot.jpg") top center no-repeat #250d1d;
  height: 450px;
}

.gamebutton-container .game-button.slot-button-1093,
.gamebutton-container .game-button.slot-button-1067,
.gamebutton-container .game-button.slot-button-1066,
.gamebutton-container .game-button.slot-button-1065,
.gamebutton-container .game-button.slot-button-1008,
.gamebutton-container .game-button.slot-button-1086,
.gamebutton-container .game-button.slot-button-1046,
.gamebutton-container .game-button.slot-button-1159,
.gamebutton-container .game-button.slot-button-1161,
.gamebutton-container .game-button.slot-button-1152,
.gamebutton-container .game-button.slot-button-1178,
.gamebutton-container .game-button.slot-button-1174,
.gamebutton-container .game-button.slot-button-1143,
.gamebutton-container .game-button.slot-button-1156,
.gamebutton-container .game-button.slot-button-1124,
.gamebutton-container .game-button.slot-button-1099,
.gamebutton-container .game-button.slot-button-1080,
.gamebutton-container .game-button.slot-button-1105,
.gamebutton-container .game-button.slot-button-1111,
.gamebutton-container .game-button.slot-button-1138,
.gamebutton-container .game-button.slot-button-1033,
.gamebutton-container .game-button.slot-button-1140,
.gamebutton-container .game-button.slot-button-1125 {
  background-size: contain !important;
}
.gamebutton-container .game-button.slot-button-1143 {
  background: url("/common/images/gamebuttons/slot/slot-button-1143.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1156 {
  background: url("/common/images/gamebuttons/slot/slot-button-1156.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1124 {
  background: url("/common/images/gamebuttons/slot/slot-button-1124.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1099 {
  background: url("/common/images/gamebuttons/slot/slot-button-1099.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1080 {
  background: url("/common/images/gamebuttons/slot/slot-button-1080.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1105 {
  background: url("/common/images/gamebuttons/slot/slot-button-1105.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1111 {
  background: url("/common/images/gamebuttons/slot/slot-button-1111.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1138 {
  background: url("/common/images/gamebuttons/slot/slot-button-1138.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1033 {
  background: url("/common/images/gamebuttons/slot/slot-button-1033.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1140 {
  background: url("/common/images/gamebuttons/slot/slot-button-1140.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1125 {
  background: url("/common/images/gamebuttons/slot/slot-button-1125.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1093 {
  background: url("/common/images/gamebuttons/slot/slot-button-1093.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1067 {
  background: url("/common/images/gamebuttons/slot/slot-button-1067.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1066 {
  background: url("/common/images/gamebuttons/slot/slot-button-1066.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1065 {
  background: url("/common/images/gamebuttons/slot/slot-button-1065.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1008 {
  background: url("/common/images/gamebuttons/slot/slot-button-1008.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1086 {
  background: url("/common/images/gamebuttons/slot/slot-button-1086.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1046 {
  background: url("/common/images/gamebuttons/slot/slot-button-1046.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1159 {
  background: url("/common/images/gamebuttons/slot/slot-button-1159.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1161 {
  background: url("/common/images/gamebuttons/slot/slot-button-1161.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1152 {
  background: url("/common/images/gamebuttons/slot/slot-button-1152.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1178 {
  background: url("/common/images/gamebuttons/slot/slot-button-1178.png") no-repeat;
}
.gamebutton-container .game-button.slot-button-1174 {
  background: url("/common/images/gamebuttons/slot/slot-button-1174.png") no-repeat;
}

.slots .button-container {
  top: 88px;
}

.slots .promo-text-slider {
  position: absolute;
  top: 135px;
  left: 130px;
  width: 410px;
  height: 190px;
  text-align: center;
}

.slots .promo-text-slider img {
  height: 100%;
}

.slots .promo-text-slider .promo-text-btn {
  width: 200px;
  height: 40px;
  border-radius: 20px;
  border: 0;
  color: #ffffff;
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
}
