.ngdialog-forgot.ngdialog.ngdialog-theme-default .ngdialog-content{
  width: 360px;
  background: transparent;
}

.ngdialog-forgot-page h4{
  font-size: 20px;
  color: #a82a1d;
  font-weight: bold;
}
.ngdialog-forgot-page .forgot__label{
  border-top: 1px dotted #ccc;
  padding: 5px 10px 12px 10px;
  color: #999;
}
.ngdialog-forgot-page .btn:hover, .btn:focus, .btn.focus{
 color: #ffffff;
}
.ngdialog-forgot-page .btn{
  padding: 5px 30px;
  border-radius: 5px;
}
.ngdialog-forgot-page{
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 6px;
  box-shadow: 2px 2px 10px #989898;
  padding-top: 15px;
}

.ngdialog-forgot__content{
  padding: 10px;
}

.ngdialog-forgot-page .form-control{
  height: 30px;
  border-radius: 0;
  border-color: #d6d6d6;
  font-size: 12px;
  color: #454545;
  box-shadow: none;
  margin: 0 0 7px 0;
}


.ngdialog-forgot.ngdialog.ngdialog-theme-default .ngdialog-close:before {
  color: #333333;
  top: 5px;
  right: 5px;
}

.ngdialog-forgot.ngdialog.ngdialog-theme-default .ngdialog-close:hover:before,
.ngdialog-forgot.ngdialog.ngdialog-theme-default .ngdialog-close:active:before{
  background-color: #212121;
  padding: 2px;
  color: #ffffff;
}

.ngdialog-forgot-page .form-inputs{
 text-align: left;
  padding: 0 16px;
}