.gamebutton-container {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin: 20px 0;
}

.gamebutton-container .game-button{
  width: 410px;
  height: 240px;
  cursor: pointer;
  transition: all .8s;
  position: relative;
  margin: 10px 5px;
}
.slots .gamebutton-container .game-button {
  width: calc(100% / 4 - 10px);
  height: 180px;
}

.button-container {
  position: absolute;
  width: 100%;
  top: 112px;
  padding: 20px;
}

.button-container a {
  display: block;
  width: 100px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  border-radius: 20px;
  border: 1px solid #fff;
}

.button-container a:hover {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9ac33+0,b4711f+100 */
  background: #e9ac33; /* Old browsers */
  background: -moz-linear-gradient(top,  #e9ac33 0%, #b4711f 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #e9ac33 0%,#b4711f 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #e9ac33 0%,#b4711f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ac33', endColorstr='#b4711f',GradientType=0 ); /* IE6-9 */
  border: 1px solid #e9ac33;
}

.button-container a:first-child {
  margin-bottom: 10px;
}

.gamebutton-hover{
  background: url("../images/overlay.png") repeat-x;
  width: 100%;
  position: absolute;
  opacity: 0;
  height: inherit;
}

.game-details{
  padding: 10px;
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
}

.game-title{
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
}

.game-details .btn{
  text-transform: uppercase;
  padding: 0 10px;
}

.games-gamebutton{
  border: 1px solid #c4c4c4;
  margin-left: -1px;
  position: relative;
}

.game-title__title {
  display: flex;
}

.game-title__button.second button{
  position: absolute;
  right: 10px;
}
