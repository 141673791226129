.main-banner-container{
  background: #250d1d;
}

.banner-main .slick-dots li {
  margin: 0;
}

.banner-main .slick-dots li.slick-active button:before{
  opacity: 1;
  color: #dfa030 !important;
  height: 12px;
  width: 12px;
}

.banner-main .slick-dots li button:before {
  font-size: 12px;
  opacity: 1;
  color: #666666;
  height: 12px;
  width: 12px;
}

.main-banner-container .banner-main img {
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.main-banner-container .promo-text-slider {
  position: absolute;
  top: -315px;
  left: 130px;
  width: 410px;
  height: 190px;
  text-align: center;
}

.main-banner-container .promo-text-slider img {
  height: 100%;
}

.main-banner-container .promo-text-slider .promo-text-btn {
  width: 200px;
  height: 40px;
  border-radius: 20px;
  border: 0;
  color: #ffffff;
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
}

.main-banner-container .promo-text-slider .promo-text-btn:hover {
  background: #666666;
}

.slick-dots{
  margin-left: 5px;
}
