.product-advantage{
  border-left: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  padding: 0 9px;
}

.product-advantage-container .item{
  padding: 10px 5px 0;
}

.product-advantage-container .item:nth-child(5) p{
  margin-bottom: 0;
}

.product-advantage-container .item h1{
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
}

.product-advantage-container .item p{
  color: #666666;
}
