.ngdialog-main-default__content .balance .btn-refresh {
  height: 35px;
  width: 40px;
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
  border: 0;
  color: #ffffff;
}

.ngdialog-main-default__content .balance .btn-refresh:hover {
  border: 0;
  background: #666666;
}

.ngdialog-main-default__content .balance .balance-overflow {
  height: 520px;
  overflow-y: auto;
  padding-right: 5px;
}

.walletCat h4.category {
  height: 40px;
  margin: 0;
  line-height: 40px;
  background: #666666;
  color: #ffffff;
  padding: 0 20px;
}

.balance__content{
  background: #ffffff;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  padding: 0 10px;
  border-bottom: 1px solid #dddfe2;
}

.balance__content.balance-total {
  background: #000000;
  color: #fff;
  margin-right: 15px;
  margin-top: 5px;
}

.balance__content:nth-child(even){
  background-color: #f2f2f2;
}

.ngdialog-wallet-page form .form-control {
  height: 40px;
  border-radius: 0;
  box-shadow: none;
  background: #f2f2f2;
  border: 1px solid #dddfe2;
}

.transfer form{
  padding: 10px 20px 20px;
  background-color: #fcfcfc;
  border: 1px solid #dddfe2;
}

.transfer label {
  margin-bottom: 10px;
  font-size: 14px;
}

.transfer fieldset {
  padding: 10px 0;
}

.transfer .btn-clear {
  margin: 0;
  width: 60px !important;
}

.deposit form fieldset,
.withdraw form fieldset,
.change-password form fieldset{
  padding: 20px 0;
  border-bottom: 1px solid #e5e5e5;
}

.deposit fieldset:last-of-type,
.withdraw fieldset:last-of-type{
  border-bottom: 0;
  margin-bottom: 5px;
}

.deposit label,
.withdraw label{
  font-size: 14px;
  line-height: 40px;
  margin-bottom: 0;
  float: left;
  min-width: 180px;
  padding-left: 20px;
}

.deposit label span + span,
.withdraw label span + span,
.change-password label span + span{
  color: #9b001b;
}

.deposit .col-xs-5 span,
.withdraw .col-xs-5 span{
  line-height: 34px;
}

.deposit .col-xs-6, .withdraw .col-xs-6,
.deposit .col-xs-7, .withdraw .col-xs-7{
  padding: 0;
}

.deposit .wallet-button,
.withdraw .wallet-button,
.bonus-history .btn,
.coupon .btn{
  padding: 7px 10px;
}

.bank-withdraw {
  width: calc(100% / 2);
}

.bank-withdraw:first-child {
  margin-right: 5px;
}

.withdraw fieldset:nth-child(3) .col-xs-3{
  padding: 0;
}

.history-table.table > tbody > tr > th,
.history-table.table > tbody > tr > td{
  padding: 8px;
  vertical-align: middle;
  line-height: 100%;
  background-color: #666666;
  color: #fff;
  text-align: center;
  font-weight: 700;
  width: 14%;
  height: 40px;
}

.history-table.table > tbody > tr > th {
  font-size: 14px;
}

.history-table.table > tbody > tr > td{
  background-color: #f2f2f2;
  color: #666666;
  padding: 10px;
  font-weight: normal;
  border-bottom: 1px solid #dddfe2;
}

.history-table.table > tbody > tr:nth-of-type(odd) td {
  background: #ffffff;
}

.bonus-history .col-xs-4,
.bonus-history .col-xs-2{
  padding:  0 10px 0 0;
}

.bonus-history form fieldset{
  background: #f2f2f2;
  padding: 10px 20px !important;
  margin: 0 !important;
}

.bonus-history .btn-submit {
  height: 40px;
}

.coupon .col-xs-10,
.coupon .col-xs-2{
  padding:  0 5px 0 0;
}

.btn-group.btn-group-history {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.btn-group.btn-group-history .btn-rollover,
.btn-group.btn-group-history .btn-rollover.active,
.btn-group.btn-group-history .btn-rollover:hover{
  width: calc(100% / 2 - 5px);
  height: 40px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9ac33+0,b4711f+100 */
  background: #e9ac33; /* Old browsers */
  background: -moz-linear-gradient(top,  #e9ac33 0%, #b4711f 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #e9ac33 0%,#b4711f 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #e9ac33 0%,#b4711f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ac33', endColorstr='#b4711f',GradientType=0 ); /* IE6-9 */
  color: #ffffff;
}

.btn-group.btn-group-history .btn-rollover {
  background: #000000;
  color: #ffffff;
  width: calc(100% / 2 - 5px);
  height: 40px;
}

.rollover-history .history-table.table > tbody > tr > td,
.cash-history .history-table.table > tbody > tr > td{
  height: 60px;
}

.rollover-history span.label {
  padding: 14.5px 5px;
  border-radius: 0;
}

.rollover-history span.label.label-success {
  background: #5cb85c;
}

.change-password label {
  font-size: 14px;
  line-height: 40px;
  margin-bottom: 0;
}

.change-password .signup-error {
  background: #f2f2f2;
  line-height: 40px;
  padding-left: 20px;
  color: #9b001b;
  margin: 0;
}

/* New Wallet */
.ngdialog-wallet-page .deposit-menu{
  display: flex;
}

.ngdialog-wallet-page .deposit-menu:hover{
  cursor:pointer;
  border-color: #e6e6e6;
}

.ngdialog-wallet-page .deposit-menu.active{
  color: #db216d;;
}

.ngdialog-wallet-page .type-deposit-container{
  width: 320px;
  height: 50px;
  background: #f2f2f2;
  border: 1px solid #dddfe2;
  padding: 10px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.ngdialog-wallet-page .bank-icons{
  width: 20px;
  height: 20px;
  display: inline-block;
  float: left;
  vertical-align: middle;
  margin: 5px 10px;
}

.ngdialog-wallet-page .icon-aya{ background: url("/common/images/banks/icon-aya.png") 0 0 no-repeat; }
.ngdialog-wallet-page .icon-bangkok{ background: url("/common/images/banks/icon-bangkok.png") 0 0 no-repeat; }
.ngdialog-wallet-page .icon-cb{ background: url("/common/images/banks/icon-cb.png") 0 0 no-repeat; }
.ngdialog-wallet-page .icon-kasikorn{ background: url("/common/images/banks/icon-kasikorn.png") 0 0 no-repeat; }
.ngdialog-wallet-page .icon-kbz{ background: url("/common/images/banks/icon-kbz.png") 0 0 no-repeat; }
.ngdialog-wallet-page .icon-siam{ background: url("/common/images/banks/icon-siam.png") 0 0 no-repeat; }

.bank-details {
  display: inline-block;
  position: relative;
  width: 125px;
  line-height: 14px;
}

.bank-details::after {
  content: "";
  width: 2px;
  height: 10px;
  position: absolute;
  right: 0;
  bottom: 4px;
  background: #dddfe2;
}

.ngdialog-wallet-page .type-deposit-container .radio{
  position:absolute;
  left:-9999px
}

.ngdialog-wallet-page .bank-name,
.ngdialog-wallet-page .bank-holder{
  display: block;
}

.ngdialog-wallet-page .bank-contact {
  width: 80px;
  padding: 12px 10px 0;
  text-align: center;
}

.ngdialog-wallet-page .btn-copy {
  width: 60px;
  height: 30px;
  background: #666666;
  color: #ffffff;
  border: 0;
}

.ngdialog-wallet-page .btn-copy:hover {
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
}

/*INTL INPUT*/
.ngdialog-wallet-page .intl-tel-input .selected-flag .iti-flag{
  margin: 1px 0 0 -7px;
}

.ngdialog-wallet-page .flag-dropdown{
  padding: 7px 0px 5px 13px;
  position: absolute;
  background: #dbdbdb;
  z-index: 99;
  height: 32px;
  margin: 1px
}
.ngdialog-wallet-page .intl-tel-input .selected-flag {
  cursor: pointer;
  outline: 0;
}

.ngdialog-wallet-page #contactNumber{padding-left: 60px;}
.ngdialog-wallet-page #contactNumber2{padding-left: 60px;}

.change-password .has-not-used .form-control,
.change-password .has-not-used .form-control:hover,
.change-password .has-not-used .form-control:focus{
  background: #f2f2f2;
}

.deposit button.btn.btn-gametype {
  padding: 0 20px;
  height: 45px;
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
  border: 0;
  color: #fff;
  margin-right: 5px;
}

.deposit button.btn.btn-gametype.active,
.deposit button.btn.btn-gametype:hover{
  background: #666666;
  box-shadow: none;
}

.deposit .type-label {
  line-height: 45px;
}

.withdraw .type-label {
  line-height: 40px;
}

.deposit-menu__container {
  overflow-y: scroll;
  height: 325px;
  width: calc(100% - 180px);
}

.deposit .btn-submit,
.withdraw .btn-submit,
.change-password .btn-submit {
  width: 50%;
  margin: 0 auto;
}
