.ngdialog-signup.ngdialog.ngdialog-theme-default .ngdialog-content{
  width: 800px;
  background: transparent;
}

.ngdialog-signup-page {
  background-color: #ffffff;
  border: 1px solid #dddfe2;
}

.ngdialog-signup__header {
  text-align: center;
  height: 65px;
  line-height: 65px;
  border-bottom: 1px solid #dddfe2;
}

.ngdialog-signup__content form {
  padding: 30px 20px;
  border-top: 1px solid #dddfe2;
}

.ngdialog-signup__title{
  text-align: center;
  margin: 10px 0;
}

.ngdialog-signup__title h1{
  margin: 0;
  font-weight: bold;
  color: #c0c0c0;
  font-size: 30px;
  text-transform: uppercase;
}

.ngdialog-signup__title img{
  vertical-align: text-top;
}

.ngdialog-signup-page fieldset{
  padding-bottom: 15px;
}

.ngdialog-signup-page .col-sm-3.details{
  height: 40px;
}

.ngdialog-signup-page .col-sm-4 p{
  margin-bottom: 0;
}

.ngdialog-signup-page .col-sm-4{
  font-size: 12px;
  line-height: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 10px;
}

.ngdialog-signup-page label{
  line-height: 40px;
  margin-bottom: 0;
}

.ngdialog-signup-page label span + span{
  color: #9b001b;
}

.ngdialog-signup-page .form-control{
  border-radius: 0;
  box-shadow: none;
  border-color: #dddfe2;
  height: 40px;
  background: #f2f2f2;
}

.has-error .form-control{
  background: url("../images/icon-error.png") #f2f2f2 right 10px center no-repeat;
  border-color: #9b001b;
}

.has-success .form-control{
  background: url("../images/icon-valid.png") #f2f2f2 right 10px center no-repeat;
  border-color: #3c763d;
}

.has-not-used .form-control,
.has-not-used .form-control:hover,
.has-not-used .form-control:focus{
  background: none #f2f2f2;
  border-color: #dddfe2;
  box-shadow: none;
}

.signup-error{
  color: #9b001b;
}

.ngdialog-signup-page .signup-error{
  color: #9b001b;
  margin: 0;
  position: relative;
  line-height: 40px;
  display: block;
  background: #f2f2f2;
  padding-left: 20px;
}

span.signup-inquiry {
  position: relative;
  line-height: 40px;
  display: block;
  padding-left: 20px;
}

.has-success .signup-error{ display: none; }
.has-error .signup-inquiry { display: none; }

/*INTL INPUT*/
.ngdialog-signup .intl-tel-input .selected-flag .iti-flag{
  margin: 1px 0 0 -7px;
}

.ngdialog-signup .flag-dropdown{
  padding: 12px 0px 12px 13px;
  position: absolute;
  background: #f2f2f2;
  z-index: 99;
  height: 38px;
  margin: 1px;
}
.ngdialog-signup .intl-tel-input .selected-flag {
  cursor: pointer;
  outline: 0;
}

.ngdialog-signup #contactNumber{
  padding-left: 60px;
}

.ngdialog-signup .iti.iti--allow-dropdown {
  width: 100%;
}


.ngdialog-signup-page .month .form-control,
.ngdialog-signup-page .day .form-control,
.ngdialog-signup-page .year .form-control,
.ngdialog-signup-page .bank .form-control{
  padding: 6px 10px;
  font-size: 12px;
}

.ngdialog-signup-page .month{
  padding-left: 5px;
  padding-right: 5px;
}
.ngdialog-signup-page .has-error-birthday .signup-inquiry-birthdate {
  /*display: none;*/
}

.ngdialog-signup-page .month, .ngdialog-signup-page .day {
  padding-left: 5px !important;
}

.ngdialog-signup-page span.error {
  padding-right: 113px;
}

.ngdialog-signup-page .btn-yellow {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9ac33+0,b4711f+100 */
  background: #e9ac33; /* Old browsers */
  background: -moz-linear-gradient(top,  #e9ac33 0%, #b4711f 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #e9ac33 0%,#b4711f 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #e9ac33 0%,#b4711f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ac33', endColorstr='#b4711f',GradientType=0 ); /* IE6-9 */
  color: #ffffff;
}
