.transaction-page .transaction-container {
  padding-left: 15px;
}
.transaction-page .transaction-table {
  width: 100%;
}

.transaction-page .table-heading {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #b4721f;
}

.transaction-page .table-heading span {
  width: calc(100% / 3);
  height: 40px;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  color: #666666;
  background: #fcfcfc;
}

.transaction-page .table-heading span.active,
.transaction-page .table-heading span:hover{
  color: #ffffff;
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
}

.transaction-page tbody > tr {
  height: 40px;
  font-size: 12px;
  background: #f2f2f2;
  color: #666666;
  font-weight: 600;
}

.transaction-page tbody > tr:nth-of-type(odd) {
  background: #fcfcfc;
}

.transaction-page tbody > tr > td {
  padding: 0 10px;
}

.transaction-page tbody > tr.deposit > td,
.transaction-page tbody > tr.withdraw > td {
  width: calc(100% / 3);
}

.transaction-page tbody > tr > td.goldTxt {
  color: #eaac33;
}
