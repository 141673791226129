.sports-container{
  background: url("/common/images/banner/main-banner-sports.jpg") top center no-repeat #250d1d;
  height: 450px;
}

.view-container .sports-1095,
.view-container .sports-1068{
  width: 1040px;
  position: relative;
  margin: 0 auto;
}

.sports .gamebutton-container .game-button{
  width: calc(100% / 3 - 10px);
}

.gamebutton-container .game-button.sports-button-1108,
.gamebutton-container .game-button.sports-button-1096,
.gamebutton-container .game-button.sports-button-1044,
.gamebutton-container .game-button.sports-button-1095,
.gamebutton-container .game-button.sports-button-1068,
.gamebutton-container .game-button.sports-button-1106{
  background-size: cover !important;
}

.gamebutton-container .game-button.sports-button-1108 {
  background: url("/common/images/gamebuttons/sports/sports-button-1108.png") no-repeat;
}
.gamebutton-container .game-button.sports-button-1096 {
  background: url("/common/images/gamebuttons/sports/sports-button-1096.png") no-repeat;
}
.gamebutton-container .game-button.sports-button-1044 {
  background: url("/common/images/gamebuttons/sports/sports-button-1044.png") no-repeat;
}
.gamebutton-container .game-button.sports-button-1095 {
  background: url("/common/images/gamebuttons/sports/sports-button-1095.png") no-repeat;
}

.gamebutton-container .game-button.sports-button-1068 {
  background: url("/common/images/gamebuttons/sports/sports-button-1068.png") no-repeat;
}

.gamebutton-container .game-button.sports-button-1106 {
  background: url("/common/images/gamebuttons/sports/sports-button-1106.png") no-repeat;
}

.sports .promo-text-slider {
  position: absolute;
  top: 135px;
  left: 130px;
  width: 410px;
  height: 190px;
  text-align: center;
}

.sports .promo-text-slider img {
  height: 100%;
}

.sports .promo-text-slider .promo-text-btn {
  width: 200px;
  height: 40px;
  border-radius: 20px;
  border: 0;
  color: #ffffff;
  background: #e9ac33;
  background: -moz-linear-gradient(top, #eaac33 0%, #b57220 100%);
  background: -webkit-linear-gradient(top, #eaac33 0%,#b57220 100%);
  background: linear-gradient(to bottom, #eaac33 0%,#b57220 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaac33', endColorstr='#b57220',GradientType=0 );
}


/*view sports*/

.view-sports .sports-container {
  background: unset;
  height: unset;
}

.sports-view-nav .main-container{
  padding-right: 24px;
}

.sports-view-nav {
  background: #0a0a0a;
}

.sports-view-nav .sports-nav {
  margin-bottom: 0;
  padding: 0;
}

.sports-nav > li {
  display: inline-block;
  list-style: none;
  font-size: 14px;
  letter-spacing: -1px;
  text-transform: uppercase;
  font-weight: 600;
  height: 46px;
  line-height: 45px;
  vertical-align: middle;
  margin: 0;
  padding: 0 15px;
  text-align: center;
}

.sports-view-nav > li:first-child{
  padding-left: 8px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .sports-nav > li {
    padding: 0 11px;
  }
}

.sports-nav > li > a {
  color: #999;
}

.sports-nav > li > a:hover,
.sports-nav > li > a.active {
  color: #eaac33;
  text-decoration: none;
}

.sports-view-nav .user-balance-container {
  width: 220px;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  line-height: 28px;
}

.sports-view-nav .user-balance-container .total-balance {
  border: 1px solid #585858;
  background: #302e36;
  padding: 0 8px;
  height: 31px;
  width: 189px;
  float: left;
  letter-spacing: 0;
}

.sports-view-nav .user-balance-container .total-balance:hover {
  border-color: #f5aa18;
  cursor: pointer;
}

.sports-view-nav .user-balance-container .show-wallet {
  float: left;
  width: 31px;
  height: 31px;
  background: #b2423f;
  font-size: 25px;
  text-align: center;
  padding-right: 6px;
}

iframe#sports1,
iframe#sports2,
iframe#sports3 {
  height: calc(100vh - 200px);
  /*height: 100vh;
  padding-bottom: 20px;*/
}

.ngdialog-cmd.ngdialog.ngdialog-theme-default .ngdialog-content{
  width: 900px;
}
.ngdialog-cmd.ngdialog.ngdialog-theme-default .ngdialog-close:hover:before, .ngdialog-cmd.ngdialog.ngdialog-theme-default .ngdialog-close:active:before {
  background: unset;
}
.ngdialog-cmd.ngdialog.ngdialog-theme-default .ngdialog-close:before {
  background: unset;
}

/*pinnacle popup*/
.ngDialog-pinnacle {
  background: url("/common/images/main/sports-button/bg-pinnacle.jpg") no-repeat;
  width: 900px;
  height: 500px;
  margin-top: 100px;
}

.ngDialog-pinnacle .popup-content {
  padding: 78px 125px;
  border: unset;
}

.ngdialog-pinnacle.ngdialog.ngdialog-theme-default .ngdialog-content{
  width: 900px;
}
.ngdialog-pinnacle.ngdialog.ngdialog-theme-default .ngdialog-close:hover:before, .ngdialog-pinnacle.ngdialog.ngdialog-theme-default .ngdialog-close:active:before {
  background: unset;
}
.ngdialog-pinnacle.ngdialog.ngdialog-theme-default .ngdialog-close:before {
  background: unset;
}

.ngDialog-pinnacle .popup-content div {
  display: inline-block;
}

.classic-pinnacle {
  background: url("/common/images/main/sports-button/popup-button-1060-old.jpg") no-repeat;
  width: 305px;
  height: 336px;
  margin-right: 35px;
}

.classic-pinnacle:hover {
  background: url("/common/images/main/sports-button/popup-button-1060-old-hover.jpg") no-repeat;
  cursor: pointer;
}

.new-pinnacle {
  background: url("/common/images/main/sports-button/popup-button-1060-new.jpg") no-repeat;
  width: 305px;
  height: 336px;
}

.new-pinnacle:hover {
  background: url("/common/images/main/sports-button/popup-button-1060-new-hover.jpg") no-repeat;
  cursor: pointer;
}

.sports-view-nav .sports-nav .e-sports{
  display: none;
}

