.game-nav {
  background: #f2f2f2;
  border: 1px solid #dddfe2;
  padding: 15px;
  display: flex;
  margin-bottom: 10px;
}

.game-nav .links {
  width: calc(100% / 4);
  text-align: center;
  height: 250px;
  margin-right: 10px;
  position: relative;
}

.game-nav .links:last-child {
  margin-right: 0;
}

.game-nav .links.sports {
  background: url("/common/images/mainbutton/game-button-1.jpg") no-repeat;
}

.game-nav .links.live {
  background: url("/common/images/mainbutton/game-button-2.jpg") no-repeat;
}

.game-nav .links.slot {
  background: url("/common/images/mainbutton/game-button-3.jpg") no-repeat;
}

.game-nav .links.fish {
  background: url("/common/images/mainbutton/game-button-4.jpg") no-repeat;
}

.game-nav .title-container {
  padding: 0 64px 20px;
  position: absolute;
  bottom: 0;
}

.game-nav .title-container h3 {
  color: #fff;
  font-size: 18px;
}

.game-nav .title-container span {
  color: #aeaeae;
  font-size: 12px;
}


/*Overlay*/

.links:hover .overlay{
  opacity: 1;
}

.links .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 1s;
  opacity: 0;
  background: #00000050;
  border: 1px solid #eaac33;
}

.links .overlay .tbl {
  display: table;
  width: 100%;
  height: 100%;
}

.links .overlay .center {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: auto;
  height: auto;
}

.links .overlay .title {
  width: 153px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  margin: 0 auto;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9ac33+0,b4711f+100 */
  background: #e9ac33; /* Old browsers */
  background: -moz-linear-gradient(top,  #e9ac33 0%, #b4711f 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #e9ac33 0%,#b4711f 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #e9ac33 0%,#b4711f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ac33', endColorstr='#b4711f',GradientType=0 ); /* IE6-9 */
  color: #ffffff;
}

.links .overlay .title:hover {
  background: #666666;
}
