.header{
  display: flex;
  height: 90px;
  align-items: center;
  justify-content: space-between;
}

.header .logo-container{
  width: 230px;
  padding: 0 10px;
  cursor: pointer;
}

.header .login-container{
  padding-left: 10px;
}

.login-container input{
  width: 120px;
  height: 40px;
  border: 1px solid #dddfe2;
  background: #f2f2f2;
  color: #666666;
  margin-right: 10px;
  font-size: 12px;
  vertical-align: middle;
  padding-left: 10px;
}

.login-container input:focus{
  outline: none;
}

.login-container .get-balance.refresh {
  margin-right: 10px;
}

.login-container .get-balance {
  width: 40px;
  height: 40px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  line-height: 40px;
  padding: 0;
}

.login-container .btn.btn-login,
.login-container .btn.btn-signup,
.login-container .btn.btn-logout,
.login-container .btn.btn-arrow,
.login-container .btn.btn-wallet{
  width: 100px;
  height: 40px;
  margin-right: 10px;
  color: #ffffff;
  border-radius: 0;
  font-weight: 600;
}

.login-container .btn.btn-arrow{
  width: 40px;
  padding: 0;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.login-container .btn.btn-login,
.login-container .btn.btn-logout,
.login-container .btn.btn-signup:hover,
.login-container .btn.btn-wallet:hover,
.login-container .btn.btn-arrow:hover,
.login-container .get-balance:hover,
.btn.btn-primary:hover{
  background: #666666;
  border: 0;
}

.login-container .btn.btn-signup,
.login-container .btn.btn-wallet,
.login-container .btn.btn-arrow,
.login-container .get-balance,
.login-container .btn.btn-login:hover,
.login-container .btn.btn-logout:hover,
.btn.btn-primary {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9ac33+0,b4711f+100 */
  background: #e9ac33; /* Old browsers */
  background: -moz-linear-gradient(top,  #e9ac33 0%, #b4711f 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #e9ac33 0%,#b4711f 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #e9ac33 0%,#b4711f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9ac33', endColorstr='#b4711f',GradientType=0 ); /* IE6-9 */
  color: #ffffff;
  border: 0;
}

.member-balance-container{
  display: flex;
  font-size: 14px;
  align-items: center;
}

.member-balance-container .text-yellow{
  color: #f5aa18;
  margin-right: 10px;
}

.member-balance-container .total-balance-container{
  width: 260px;
  background: #f2f2f2;
  height: 40px;
  color: #666666;
  padding: 0 20px;
  line-height: 40px;
  border: 1px solid #dddfe2;
}

.navigation{
  background: #0f0f0f;
}

.navigation .main-container{
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation .main-links>ul,
.navigation .sublinks>ul{
  width: 625px;
  display: flex;
  justify-content: space-around;
  font-size: 14px;
}

.navigation ul>li>a:hover,
.navigation ul>li>a:focus,
.navigation ul>li>a.active{
  color: #ffffff;
}

.navigation .sublinks>ul{
  width: 230px;
}

/*drop down wallet*/
.dropdown-wallet-container{
  position: absolute;
  width: 1260px;
  border: 1px solid #dddfe2;
  margin: 0 auto;
  left: 0;
  top: 90px;
  background: #fcfcfc;
  display: none;
  z-index: 2;
  height: 571px;
}

.flex-class{
  display: flex;
  justify-content: space-between;
}

.gsp-balance-container{
  width: calc(100% / 4);
  height: 470px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.gsp-balance-container:first-child{
}

.gsp-balance-container:last-child{
}

.gsp-balance-container.transfer-conn{
  border-right: 0;
  margin-right: 0;
}

.slot-balance-container{
  width: calc(100% / 5);
  height: 482px;
  border-right: 1px solid #303030;
  border-left: 1px solid #303030;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}

.balance-title{
  background: #666666;
  color: #ffffff;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 14px;
  border: 1px solid #dddfe2;
}

.gsp-table-container{
  display: flex;
  justify-content: space-between;
}

.gsp-list-container{
  width: 100%;
}

.gsp-list-item{
  width: 100%;
  height: 52px;
  background: #f2f2f2;
  border: 1px solid #dddfe2;
  color: #666666;
  text-align: right;
  padding: 5px 20px;
  font-size: 14px;
}

.gsp-list-item .amount{
  color: #f5aa18;
}

.gsp-list-item p{
  margin: 0;
  white-space: nowrap;
}

.gsp-list-item p.gsp-name {
  font-weight: bold;
}

.gsp-table-container .gsp-list-item:nth-child(even){
  background: #fcfcfc;
}

.gsp-balance-container.transfer-conn .dd-transfer-container{
  width: 100%;
  padding: 20px 20px 152px 20px;
  border: 1px solid #dddfe2;
}

.gsp-balance-container.transfer-conn select{
  height: 40px;
  background: #f2f2f2;
  border-color: #dddfe2;
  border-radius: 0;
  color: #999999;
  font-weight: 700;
  margin-bottom: 10px;
}

.gsp-balance-container.transfer-conn .form-control{
  padding-right: 25px;
  border-radius: 0;
  color: #666666;
  font-weight: 700;
  margin-bottom: 10px;
  background: #f2f2f2;
  border-color: #dddfe2;
}

.gsp-balance-container.transfer-conn .won-text {
  position: absolute;
  top: 8px;
  right: 10px;
  margin: 0;
  font-size: 14px;
  color: #666666;
}

.deposit-widthdraw-container{
  width: 100%;
  padding: 20px 20px;
  align-items: center;
  border: 1px solid #dddfe2;
}

.deposit-widthdraw-container button:first-child {
  margin-bottom: 10px;
}

.deposit-widthdraw-container .btn.btn-primary{
  height: 40px;
  width: 100%;
}

.dd-arrow{
  font-size: 25px;
  position: absolute;
  color: #dddfe2;
  top: -27px;
  right: 370px;
}

.gsp-list-item .label-danger{
  line-height: 15px;
  padding: 1px 5px;
}


.gsp-balance-container.sports .gsp-table-container .gsp-list-item:nth-child(odd) {
  background: #0f0f0f;
}

.gsp-balance-container.sports .gsp-table-container .gsp-list-item{
  background: #181818;
}

.btn-login.hvr-sweep-to-right:before {
  background: #d5d5d5;
  color: #666666;
}

.btn-signup.hvr-sweep-to-right:before {
  background: #ecb445;
  color: #ffffff;
}

.btn.btn-primary {
  border: 0;
  color: #ffffff;
  height: 40px;
}

.slots .gsp-list-container {
  width: calc(100% / 2);
}

.dropdown-wallet-container .slots .gsp-name {
  width: auto;
}

.gsp-balance-container.slots {
  width: 500px;
}

.gsp-list-item.balance-title-fish {
  background: #666666 !important;
  color: #ffffff;
  height: 52px;
  text-align: center;
  line-height: 41px;
  font-size: 14px;
  border: 1px solid #dddfe2;
}
