.main-info{
  margin-bottom: 10px;
  border: 1px solid #dddfe2;
  background: #fcfcfc;
  padding: 15px;
  display: flex;
  align-content: space-between;
}

.main-info .main-info__promotion,
.main-info .main-info__contact,
.main-info .main-info__transaction {
  width: calc(100% / 3);
  border-right: 2px solid #dddfe2;
}

.main-info .main-info__transaction {
  border-right: 0;
}

.main-info .title{
  color: #666666;
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 700;
}
