.ngdialog-main-default.ngdialog.ngdialog-theme-default .ngdialog-content {
  background: #ffffff;
  width: 1220px;
}

.ngdialog-wallet-page .ngdialog__heading {
  border: 1px solid #dddfe2;
  height: 65px;
  line-height: 65px;
  text-align: center;
}

.ngdialog-main-nav > li{
  float: left;
}

.ngdialog-main-nav li{
  color: #666666;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  vertical-align: middle;
  position: relative;
  height: 50px;
  line-height: 50px;
  padding: 0;
}

.ngdialog-main-nav li:hover,
.ngdialog-main-nav li:focus,
.ngdialog-main-nav li:active,
.ngdialog-main-nav li.active{
  color: #eaac33;
  border-bottom: 2px solid #eaac33;
}

.ngdialog-main-nav li:nth-child(7) > span:first-child{
  padding-right: 15px;
}

.ngdialog-main-nav li .badge{
  position: absolute;
  right: -10px;
  top: 15px;
  background: #e76765;
}

.ngdialog-main-default__content{
  padding: 20px;
  border: 1px solid #dddfe2;
}

.ngdialog-main-default__content .panel-body {
  word-break: break-all;
}

.has-error .form-control{
  background: url("../images/icon-error.png") #f2f2f2 right 10px center no-repeat !important;
  border-color: #9b001b !important;
}
